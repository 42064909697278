import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

function RegistrarIngreso() {
    const [descripcion, setDescripcion] = useState('');
    const [monto, setMonto] = useState('');
    const [cuenta, setCuenta] = useState('');
    const [fecha, setFecha] = useState('');
    const [mensaje, setMensaje] = useState(null);
    const [empresaId, setEmpresaId] = useState(null);

    useEffect(() => {
        // Obtener el objeto user desde localStorage
        const storedUser = localStorage.getItem('user');

        if (storedUser) {
            const user = JSON.parse(storedUser);
            console.log('User data from localStorage:', user);

            if (user.EmpresaId) {
                setEmpresaId(user.EmpresaId);
                console.log('EmpresaId:', user.EmpresaId);
            } else {
                console.log('EmpresaId not found in user data');
            }
        } else {
            console.log('No user data found in localStorage');
        }
    }, []);

    const handleRegistrarIngreso = async (e) => {
        e.preventDefault();

        const ingresoData = {
            empresa_id: empresaId, // Incluye el IdEmpresa
            descripcion,
            monto: parseFloat(monto),
            cuenta,
            fecha,
        };

        console.log("Datos enviados:", ingresoData);

        try {
            const response = await fetch('https://cam.gst-panel.com/finance/registrar_ingreso.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(ingresoData),
            });

            const data = await response.json();

            if (response.ok) {
                setMensaje({ type: 'success', text: 'Ingreso registrado exitosamente.' });
                // Reiniciar los campos del formulario
                setDescripcion('');
                setMonto('');
                setCuenta('');
                setFecha('');
            } else {
                setMensaje({ type: 'error', text: data.message || 'Error al registrar el ingreso.' });
            }
        } catch (error) {
            setMensaje({ type: 'error', text: 'Error de conexión con el servidor.' });
        }
    };

    return (
        <div className="container mt-5">
            <h2>Registrar Ingreso</h2>
            {mensaje && <Alert variant={mensaje.type === 'error' ? 'danger' : 'success'}>{mensaje.text}</Alert>}
            <Form onSubmit={handleRegistrarIngreso}>
                <Form.Group controlId="formDescripcion">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Descripción del ingreso"
                        value={descripcion}
                        onChange={(e) => setDescripcion(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formMonto">
                    <Form.Label>Monto</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Monto"
                        value={monto}
                        onChange={(e) => setMonto(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formCuenta">
                    <Form.Label>Cuenta Asignada</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Cuenta Asignada"
                        value={cuenta}
                        onChange={(e) => setCuenta(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formFecha">
                    <Form.Label>Fecha</Form.Label>
                    <Form.Control
                        type="date"
                        value={fecha}
                        onChange={(e) => setFecha(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-3">
                    Registrar Ingreso
                </Button>
            </Form>
        </div>
    );
}

export default RegistrarIngreso;
