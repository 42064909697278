import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import './RegistroVisitaForm.css';

const Rvisitas = ({ user }) => {
    const { ID: EmpleadoID } = user;
    const { id } = useParams();

    const [formData, setFormData] = useState({
        IdVisitas: id,
        FechaHoraLlegada: '',
        FechaHoraSalida: '',
        TipoTrabajo: '',
        DescripcionTrabajo: '',
        PersonaRecibe: '',
        CargoPersonaRecibe: '',
        FirmaPersonaRecibe: '',
        EmpleadoResponsableID: EmpleadoID,
        Solucion: '1',
    });

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const sigCanvas = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        setFormData({ ...formData, FirmaPersonaRecibe: '' });
    };

    const saveSignature = () => {
        if (!sigCanvas.current.isEmpty()) {
            const signatureData = sigCanvas.current
                .getTrimmedCanvas()
                .toDataURL('image/png')
                .split(',')[1];
            setFormData({ ...formData, FirmaPersonaRecibe: signatureData });
        } else {
            alert('Por favor, agregue una firma antes de guardar.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                'https://cam.gst-panel.com/tecnico/registerVisita.php',
                formData,
                { headers: { 'Content-Type': 'application/json' } }
            );
            setMessage(response.data.message);
            setError('');
        } catch (err) {
            console.error(err);
            setError(err.response?.data?.error || 'Error al registrar la visita');
        }
    };

    return (
        <div className="registro-visita-form-modern">
            <div className="form-scroll-container">
                <div className="form-container">
                    <h2>Registrar Visita</h2>
                    {message && <p className="alert success">{message}</p>}
                    {error && <p className="alert error">{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Fecha y Hora de Llegada</label>
                            <input
                                type="datetime-local"
                                name="FechaHoraLlegada"
                                value={formData.FechaHoraLlegada}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Fecha y Hora de Salida</label>
                            <input
                                type="datetime-local"
                                name="FechaHoraSalida"
                                value={formData.FechaHoraSalida}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Tipo de Trabajo</label>
                            <select
                                name="TipoTrabajo"
                                value={formData.TipoTrabajo}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>
                                    Seleccione
                                </option>
                                <option value="Mantenimiento">Mantenimiento</option>
                                <option value="Instalación">Instalación</option>
                                <option value="Reparación">Reparación</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Descripción del Trabajo</label>
                            <textarea
                                name="DescripcionTrabajo"
                                value={formData.DescripcionTrabajo}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Persona que Recibe</label>
                            <input
                                type="text"
                                name="PersonaRecibe"
                                value={formData.PersonaRecibe}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Cargo de la Persona</label>
                            <input
                                type="text"
                                name="CargoPersonaRecibe"
                                value={formData.CargoPersonaRecibe}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="signature-container">
                            <h3>Firma del Cliente</h3>
                            <SignatureCanvas
                                ref={sigCanvas}
                                penColor="black"
                                canvasProps={{
                                    className: 'signature-canvas-modern',
                                }}
                            />
                            <div className="signature-buttons">
                                <button
                                    type="button"
                                    onClick={clearSignature}
                                    className="btn-secondary"
                                >
                                    Limpiar
                                </button>
                                <button
                                    type="button"
                                    onClick={saveSignature}
                                    className="btn-primary"
                                >
                                    Guardar Firma
                                </button>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Solución Aplicada</label>
                            <select
                                name="Solucion"
                                value={formData.Solucion}
                                onChange={handleChange}
                                required
                            >
                                <option value="1">Sí</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <button type="submit" className="btn-submit">
                            Registrar Visita
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Rvisitas;
