// src/components/DashboardAdmin.js

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import apiService from '../services/apiService';

const DashboardAdmin = ({ user }) => {
    const { Nombre, Apellido, EmpresaId } = user;
    const navigate = useNavigate();

    const [empresa, setEmpresa] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEmpresa = async () => {
            try {
                const response = await apiService.getEmpresaById(EmpresaId);
                setEmpresa(response.data);
            } catch (err) {
                setError('Error al obtener los datos de la empresa.');
            }
        };

        fetchEmpresa();
    }, [EmpresaId]);

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col xs={12} md={8} className="text-center">
                    {error ? (
                        <Alert variant="danger">{error}</Alert>
                    ) : (
                        <>
                            {empresa && (
                                <>
                                    <img
                                        src={`https://cam.gst-panel.com${empresa.LogoEmpresa}`}
                                        alt={empresa.NombreEmpresa}
                                        style={{ width: '100px', height: 'auto', marginBottom: '20px' }}
                                    />
                                    <h1>Bienvenido, {Nombre} {Apellido}</h1>
                                    <h2>{empresa.NombreEmpresa}</h2>
                                    <p className="text-muted">Panel de Administración</p>
                                </>
                            )}
                        </>
                    )}
                </Col>
            </Row>

            <Row className="mt-4">
                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Finanzas</Card.Title>
                            <Card.Text>Gestione las finanzas de la empresa.</Card.Text>
                            <Button
                                variant="primary"
                                className="w-100"
                                onClick={() => handleNavigation('/finance')}
                            >
                                Ver Finanzas
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Agregar Usuarios</Card.Title>
                            <Card.Text>Agregar nuevos técnicos y clientes.</Card.Text>
                            <Button
                                variant="primary"
                                className="w-100"
                                onClick={() => handleNavigation('/agregar-usuario')}
                            >
                                Agregar Usuarios
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Visitas</Card.Title>
                            <Card.Text>Revise las visitas realizadas o por relizar.</Card.Text>
                            <Button
                                variant="info"
                                className="w-100"
                                onClick={() => handleNavigation('/Vervisitas')}
                            >
                                Ver visitas
                            </Button>                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Asignar Visitas</Card.Title>
                            <Card.Text>Asigne visitas a los técnicos disponibles.</Card.Text>
                            <Button
                                variant="info"
                                className="w-100"
                                onClick={() => handleNavigation('/Asignarvisitas')}
                            >
                                Asignar visita
                            </Button>

                        </Card.Body>
                    </Card>
                </Col>

                {/* Nuevo ítem: Crear Cotización */}
                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Crear Cotización</Card.Title>
                            <Card.Text>Genere cotizaciones para los proyectos.</Card.Text>
                            <Button
                                variant="success"
                                className="w-100"
                                onClick={() => handleNavigation('/CreateQuotation')}
                            >
                                Crear Cotización
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Nuevo ítem: Empezar Proyecto */}
                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Empezar Proyecto</Card.Title>
                            <Card.Text>Inicie nuevos proyectos desde aquí.</Card.Text>
                            <Button
                                variant="danger"
                                className="w-100"
                                onClick={() => handleNavigation('/empezar-proyecto')}
                            >
                                Empezar Proyecto
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default DashboardAdmin;
