// src/components/AsignarEgreso.js

import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

function AsignarEgreso() {
    const [egresoID, setEgresoID] = useState('');
    const [cuenta, setCuenta] = useState('');
    const [mensaje, setMensaje] = useState(null);

    const handleAsignarEgreso = async (e) => {
        e.preventDefault();

        const asignarEgresoData = {
            egresoID,
            cuenta,
        };

        try {
            const response = await fetch('http://tu-servidor/api/asignar-egreso', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(asignarEgresoData),
            });

            const data = await response.json();

            if (response.ok) {
                setMensaje({ type: 'success', text: 'Egreso asignado exitosamente.' });
                setEgresoID('');
                setCuenta('');
            } else {
                setMensaje({ type: 'error', text: data.message || 'Error al asignar el egreso.' });
            }
        } catch (error) {
            setMensaje({ type: 'error', text: 'Error de conexión con el servidor.' });
        }
    };

    return (
        <div className="container mt-5">
            <h2>Asignar Egreso</h2>
            {mensaje && <Alert variant={mensaje.type === 'error' ? 'danger' : 'success'}>{mensaje.text}</Alert>}
            <Form onSubmit={handleAsignarEgreso}>
                <Form.Group controlId="formEgresoID">
                    <Form.Label>ID del Egreso</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="ID del egreso"
                        value={egresoID}
                        onChange={(e) => setEgresoID(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formCuenta">
                    <Form.Label>Cuenta Asignada</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Cuenta Asignada"
                        value={cuenta}
                        onChange={(e) => setCuenta(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-3">
                    Asignar Egreso
                </Button>
            </Form>
        </div>
    );
}

export default AsignarEgreso;
