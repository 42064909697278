import React, { useState } from 'react';
import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import apiService from '../services/apiService';

const AgregarUsuarios = ({ user }) => {
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rol, setRol] = useState('2'); // Por defecto, Técnico
    const [celular, setCelular] = useState(''); // Nuevo estado para el celular
    const [mensaje, setMensaje] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const userData = {
            nombre,
            apellido,
            email,
            password,
            rol: parseInt(rol),
            idEmpresa: user.EmpresaId,
            celular, // Agregar el celular al objeto de datos
        };

        try {
            const response = await apiService.agregarUsuario(userData);
            if (response.status === 201) {
                setMensaje({ type: 'success', text: 'Usuario agregado exitosamente.' });
                setNombre('');
                setApellido('');
                setEmail('');
                setPassword('');
                setRol('2');
                setCelular(''); // Limpiar el campo celular
            } else {
                setMensaje({ type: 'error', text: 'Error al agregar el usuario.' });
            }
        } catch (error) {
            setMensaje({ type: 'error', text: 'Error de conexión con el servidor.' });
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col xs={12} md={8}>
                    <h2>Agregar Usuario</h2>
                    {mensaje && <Alert variant={mensaje.type === 'error' ? 'danger' : 'success'}>{mensaje.text}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formNombre">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nombre del usuario"
                                value={nombre}
                                onChange={(e) => setNombre(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formApellido" className="mt-3">
                            <Form.Label>Apellido</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Apellido del usuario"
                                value={apellido}
                                onChange={(e) => setApellido(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail" className="mt-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email del usuario"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword" className="mt-3">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formCelular" className="mt-3"> {/* Nuevo campo celular */}
                            <Form.Label>Celular</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Número de celular"
                                value={celular}
                                onChange={(e) => setCelular(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formRol" className="mt-3">
                            <Form.Label>Rol</Form.Label>
                            <Form.Control
                                as="select"
                                value={rol}
                                onChange={(e) => setRol(e.target.value)}
                            >
                                <option value="2">Técnico</option>
                                <option value="3">Cliente</option>
                            </Form.Control>
                        </Form.Group>

                        <Button variant="primary" type="submit" className="mt-3">
                            Agregar Usuario
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default AgregarUsuarios;
