// src/components/GestionDeudores.js

import React, { useState, useEffect } from 'react';
import { Table, Button, Alert } from 'react-bootstrap';

function GestionDeudores() {
    const [deudores, setDeudores] = useState([]);
    const [mensaje, setMensaje] = useState(null);

    useEffect(() => {
        fetchDeudores();
    }, []);

    const fetchDeudores = async () => {
        try {
            const response = await fetch('http://tu-servidor/api/deudores');
            const data = await response.json();

            if (response.ok) {
                setDeudores(data);
            } else {
                setMensaje({ type: 'error', text: 'Error al obtener los deudores.' });
            }
        } catch (error) {
            setMensaje({ type: 'error', text: 'Error de conexión con el servidor.' });
        }
    };

    return (
        <div className="container mt-5">
            <h2>Gestión de Deudores</h2>
            {mensaje && <Alert variant={mensaje.type === 'error' ? 'danger' : 'success'}>{mensaje.text}</Alert>}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Cliente</th>
                        <th>Monto Deuda</th>
                        <th>Fecha Deuda</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {deudores.map((deudor) => (
                        <tr key={deudor.ID}>
                            <td>{deudor.ID}</td>
                            <td>{deudor.ClienteID}</td>
                            <td>{deudor.MontoDeuda}</td>
                            <td>{deudor.FechaDeuda}</td>
                            <td>
                                <Button variant="warning" className="mr-2">
                                    Editar
                                </Button>
                                <Button variant="danger">Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default GestionDeudores;
