import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, ListGroup, Container, Row, Col } from 'react-bootstrap';
import VisualizarCotizacion from './VisualizarCotizacion';




const CrearCotizacion = ({ user }) => {
    const { EmpresaId } = user;

    const [clientes, setClientes] = useState([]);
    const [clientesFiltrados, setClientesFiltrados] = useState([]);
    const [cotizacion, setCotizacion] = useState({
        id_cliente: '',
        descripcion_proyecto: '',
        politicas: '',
        items: [],
        id_empresa: EmpresaId,
    });




    const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
    const [item, setItem] = useState({ descripcion: '', cantidad: 1, valor_unitario: 0 });
    const [nuevoCliente, setNuevoCliente] = useState({
        nombre: '',
        apellido: '',
        correo: '',
        telefono: '',
        direccion: '',
        empresa: EmpresaId,
    });

    const [showVisualizar, setShowVisualizar] = useState(false); // Nuevo estado para controlar la visualización



    useEffect(() => {
        const fetchClientes = async () => {
            const response = await axios.get(`https://cam.gst-panel.com/cotizacion/clientes.php?id_empresa=${EmpresaId}`);
            setClientes(response.data);
        };
        fetchClientes();
    }, [EmpresaId]);

    const handleChange = (e) => {
        setCotizacion({ ...cotizacion, [e.target.name]: e.target.value });
    };

    const handleSelectCliente = (cliente) => {
        setClienteSeleccionado(cliente);
        setCotizacion({ ...cotizacion, id_cliente: cliente.ID });
        setClientesFiltrados([]); // Limpiar la lista después de seleccionar
    };

    const handleSearchCliente = (e) => {
        const query = e.target.value.toLowerCase();
        const filtrados = clientes.filter(cliente =>
            cliente.Nombre.toLowerCase().includes(query) || cliente.Apellido.toLowerCase().includes(query)
        );
        setClientesFiltrados(filtrados);
    };

    const handleAddItem = () => {
        setCotizacion({
            ...cotizacion,
            items: [...cotizacion.items, item],
        });
        setItem({ descripcion: '', cantidad: 1, valor_unitario: 0 });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://cam.gst-panel.com/cotizacion/crearcotizacion.php', cotizacion);

            console.log(response.data); // Agregar esta línea para revisar la respuesta completa

            if (response.status === 200 || response.status === 201) {
                // alert('Cotización creada con éxito');
                setShowVisualizar(true); // Mostrar el componente de visualización de cotización

            } else {
                alert('Error al crear la cotización');
            }
        } catch (error) {
            console.error("Error al enviar la cotización:", error);
            alert('Hubo un problema al crear la cotización');
        }
    };

    const handleNuevoClienteChange = (e) => {
        setNuevoCliente({ ...nuevoCliente, [e.target.name]: e.target.value });
    };

    const handleNuevoClienteSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://cam.gst-panel.com/cotizacion/crearcliente.php', nuevoCliente);

            // Eliminar el primer carácter (1) y parsear la respuesta JSON restante
            const cleanData = JSON.parse(response.data.substring(1));

            console.log('Respuesta del servidor procesada:', cleanData);

            if (cleanData && cleanData.id_cliente) {
                setClienteSeleccionado(cleanData);
                setCotizacion({ ...cotizacion, id_cliente: cleanData.id_cliente });

                // Limpiar campos de nuevo cliente
                setNuevoCliente({ nombre: '', apellido: '', correo: '', telefono: '', direccion: '' });
                alert('Cliente creado exitosamente');
            } else {
                alert('Error al crear el cliente. La respuesta del servidor no contiene el ID del cliente.');
                console.error('Estructura inesperada en la respuesta:', cleanData);
            }
        } catch (error) {
            console.error("Error al crear el cliente:", error);
            alert('Hubo un problema al crear el cliente');
        }
    };
    // Función para regresar a crear cotización
    const handleBack = () => {
        setShowVisualizar(false);
        setCotizacion({
            id_cliente: '',
            descripcion_proyecto: '',
            politicas: '',
            items: [],
            id_empresa: EmpresaId,
        });
    };

    return (
        <Container fluid className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <Row className="justify-content-center w-100">
                <Col xs={12} md={10} lg={8}>
                    {showVisualizar ? (
                        <VisualizarCotizacion cotizacion={cotizacion} onBack={handleBack} /> // Renderiza el componente de visualización
                    ) : (
                        <div style={{ maxHeight: '90vh', overflowY: 'auto', padding: '20px', background: '#f8f9fa', borderRadius: '10px' }}>
                            <h2 className="text-center mb-4">Crear Cotización</h2>
                            <Form onSubmit={handleSubmit}>

                                {/* Buscar Cliente */}
                                <Form.Group>
                                    <Form.Label>Buscar Cliente</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Escriba para buscar cliente..."
                                        onChange={handleSearchCliente}
                                    />
                                    {clientesFiltrados.length > 0 && (
                                        <ListGroup className="mt-2">
                                            {clientesFiltrados.map((cliente) => (
                                                <ListGroup.Item
                                                    key={cliente.ID}
                                                    action
                                                    onClick={() => handleSelectCliente(cliente)}
                                                >
                                                    {cliente.Nombre} {cliente.Apellido}
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    )}
                                    {/* Mostrar el cliente seleccionado */}
                                    {clienteSeleccionado && (
                                        <p className="text-success mt-2">
                                            Cliente seleccionado: {clienteSeleccionado.Nombre} {clienteSeleccionado.Apellido}
                                        </p>
                                    )}
                                </Form.Group>

                                {/* Formulario para nuevo cliente si no hay cliente seleccionado */}
                                {!clienteSeleccionado && (
                                    <div>
                                        <h4 className="mt-4">Agregar Nuevo Cliente</h4>
                                        <Form.Group>
                                            <Form.Label>Nombre</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="nombre"
                                                value={nuevoCliente.nombre}
                                                onChange={handleNuevoClienteChange}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Apellido</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="apellido"
                                                value={nuevoCliente.apellido}
                                                onChange={handleNuevoClienteChange}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Direccion</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="direccion"
                                                value={nuevoCliente.direccion}
                                                onChange={handleNuevoClienteChange}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Correo</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="correo"
                                                value={nuevoCliente.correo}
                                                onChange={handleNuevoClienteChange}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Teléfono</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="telefono"
                                                value={nuevoCliente.telefono}
                                                onChange={handleNuevoClienteChange}
                                            />
                                        </Form.Group>
                                        <Button variant="primary" onClick={handleNuevoClienteSubmit} className="mt-3">
                                            Crear Nuevo Cliente
                                        </Button>
                                    </div>
                                )}

                                {/* Campos del Proyecto */}
                                <Form.Group>
                                    <Form.Label>Descripción del Proyecto</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="descripcion_proyecto"
                                        value={cotizacion.descripcion_proyecto}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Políticas</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="politicas"
                                        value={cotizacion.politicas}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <h4 className="mt-4">Items de la Cotización</h4>

                                <Form.Group>
                                    <Form.Label>Descripción del Item</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={item.descripcion}
                                        onChange={(e) => setItem({ ...item, descripcion: e.target.value })}
                                    />
                                </Form.Group>

                                <Row>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Cantidad</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={item.cantidad}
                                                onChange={(e) => setItem({ ...item, cantidad: parseInt(e.target.value) })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Valor Unitario</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={item.valor_unitario}
                                                onChange={(e) => setItem({ ...item, valor_unitario: parseFloat(e.target.value) })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Button variant="primary" onClick={handleAddItem} className="mt-3">
                                    Agregar Item
                                </Button>

                                <Button variant="success" type="submit" className="mt-4 d-block mx-auto">
                                    Crear Cotización
                                </Button>
                            </Form>
                        </div>
                    )}

                </Col>
            </Row>
        </Container>
    );
};

export default CrearCotizacion;
