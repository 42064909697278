

import axios from 'axios';

const API_URL = 'https://cam.gst-panel.com/';

const getClientes = () => {
    return axios.get(`${API_URL}getClientes.php`);
};

const getEmpresaById = (id) => {
    return axios.get(`${API_URL}getEmpresa.php?id=${id}`);
};


const agregarUsuario = (userData) => {
    console.log(userData)

    return axios.post(`${API_URL}usuarios/agregar_usuario.php`, {
        nombre: userData.nombre,
        apellido: userData.apellido,
        email: userData.email,
        password: userData.password,
        rol: userData.rol,
        idEmpresa: userData.idEmpresa,
        celular: userData.celular
    });
};

// Exporta todas las funciones, incluida agregarUsuario
export default {
    getClientes,
    getEmpresaById,
    agregarUsuario,
};
