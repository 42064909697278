// src/components/Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await authService.login(username, password);

            // Aquí es donde se agrega el console.log
            console.log('Respuesta del servidor:', response.data);

            if (response.status === 200) {
                console.log('Inicio de sesión exitoso:', response.data);

                // Guardar datos de usuario en localStorage o contexto global
                localStorage.setItem('user', JSON.stringify(response.data.user));

                // Redirigir al usuario al dashboard según el rol
                console.log("el rol es ", response.data.user.Rol)



                switch (response.data.user.Rol) {
                    case "1":
                        navigate('/dashboard/admin'); // Redirige al dashboard de administrador
                        break;
                    case "2":
                        navigate('/dashboard/tecnico');  // Redirige al dashboard de técnico
                        break;
                    case "3":
                        navigate('/dashboard/cliente'); // Redirige al dashboard de cliente
                        break;
                    default:

                        setError('Rol de usuario desconocido.');
                        break;
                }
            } else {
                setError(response.data.message || 'Nombre de usuario o contraseña incorrectos.');
            }
        } catch (error) {
            setError('Error al iniciar sesión.');
            console.error(error);
        }
    };

    return (
        <div className="login-container">
            <h2>Iniciar Sesión {localStorage.getItem}</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleLogin}>
                <div>
                    <label>Correo Electrónico:</label>
                    <input
                        type="email"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Contraseña:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Iniciar Sesión</button>
            </form>
        </div>
    );
}

export default Login;
