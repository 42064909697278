import React, { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const VisualizarCotizacion = ({ cotizacion, onBack }) => {
    const [empresa, setEmpresa] = useState(null);
    const [cliente, setCliente] = useState(null);
    const { id_cliente, descripcion_proyecto, politicas, items } = cotizacion;

    useEffect(() => {
        // Obtener datos de la empresa
        axios.get('https://cam.gst-panel.com/getEmpresa.php?id=1')
            .then(response => setEmpresa(response.data))
            .catch(error => console.error("Error al obtener datos de la empresa:", error));

        // Obtener datos del cliente
        axios.get(`https://cam.gst-panel.com/getCliente.php?id=${id_cliente}`)
            .then(response => setCliente(response.data))
            .catch(error => console.error("Error al obtener datos del cliente:", error));
    }, [id_cliente]);

    const calcularTotal = () => {
        return items.reduce((total, item) => total + item.cantidad * item.valor_unitario, 0);
    };


    const handleDownloadPDF = () => {
        const input = document.getElementById('cotizacion');

        // Esperar a que las imágenes estén completamente cargadas
        const loadImages = () => {
            const promises = Array.from(input.getElementsByTagName('img')).map((img) => {
                return new Promise((resolve, reject) => {
                    if (img.complete) {
                        resolve();
                    } else {
                        img.onload = resolve;
                        img.onerror = reject;
                    }
                });
            });
            return Promise.all(promises);
        };

        loadImages().then(() => {
            html2canvas(input).then((canvas) => {
                const pdf = new jsPDF();
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 190;
                const pageHeight = pdf.internal.pageSize.height;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;

                let position = 0;

                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                // Cambiar el nombre del PDF para incluir el nombre del cliente
                pdf.save(`cotizacion_${cliente.Nombre || "sin_nombre"}.pdf`);
            });
        }).catch((error) => {
            console.error("Error al cargar las imágenes:", error);
            alert("Hubo un error al cargar las imágenes. Intente nuevamente.");
        });
    };


    const handleShareWhatsApp = () => {
        const total = calcularTotal();
        const message = `Cotización\n\nDescripción del Proyecto: ${descripcion_proyecto}\n\nTotal: ${total}\n\n¡Gracias por considerar nuestros servicios!`;
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    return (

        <div>

            <br></br>
            <br></br>
            <br></br>

            <br></br>
            <br></br>            {empresa && (
                <div id="cotizacion" style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '10px', background: '#f9f9f9' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <h2>{empresa.NombreEmpresa}</h2>
                            <p><strong>NIT:</strong> {empresa.NIT}</p>
                        </div>
                        <img src={`https://cam.gst-panel.com${empresa.LogoEmpresa}`} alt="Logo de la Empresa" style={{ width: '100px' }} />
                    </div>
                    <hr />
                    {cliente && (
                        <div>
                            <h4>Datos del Cliente</h4>
                            <p><strong>Nombre:</strong> {cliente.Nombre}</p>
                            {cliente.Celular && <p><strong>Celular:</strong> {cliente.Celular}</p>}
                            {cliente.CorreoElectronico && <p><strong>Correo Electrónico:</strong> {cliente.CorreoElectronico}</p>}
                        </div>
                    )}

                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '10px', margin: '10px 0' }}>
                        <h4>Descripción del Proyecto</h4>
                        <p>{descripcion_proyecto}</p>
                    </div>

                    <h4>Items</h4>
                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                                <th style={{ padding: '10px' }}>Descripción</th>
                                <th style={{ padding: '10px' }}>Cantidad</th>
                                <th style={{ padding: '10px' }}>Valor Unitario</th>
                                <th style={{ padding: '10px' }}>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, index) => (
                                <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                                    <td style={{ padding: '10px' }}>{item.descripcion}</td>
                                    <td style={{ padding: '10px' }}>{item.cantidad}</td>
                                    <td style={{ padding: '10px' }}>${item.valor_unitario}</td>
                                    <td style={{ padding: '10px' }}>${item.cantidad * item.valor_unitario}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="3" style={{ textAlign: 'right', padding: '10px', fontWeight: 'bold' }}>Total</td>
                                <td style={{ padding: '10px', fontWeight: 'bold' }}>${calcularTotal()}</td>
                            </tr>
                        </tfoot>
                    </table>

                    <h4>Políticas</h4>
                    <p>{politicas}</p>

                    <div style={{ borderTop: '1px solid #ddd', paddingTop: '10px', marginTop: '20px' }}>
                        <p>¡Gracias por considerar nuestros servicios! {cliente ? cliente.Nombre : 'Estimado cliente'}, nos encantaría continuar trabajando contigo para cumplir todas tus necesidades.</p>
                    </div>
                </div>
            )}
            <Button variant="primary" onClick={handleDownloadPDF} className="mt-3">
                Descargar PDF
            </Button>
            <Button variant="success" onClick={handleShareWhatsApp} className="mt-3 ms-2">
                Compartir en WhatsApp
            </Button>
            <Button variant="secondary" onClick={onBack} className="mt-3 ms-2">
                Regresar
            </Button>
        </div>
    );
};

export default VisualizarCotizacion;
