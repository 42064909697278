import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

function RegistrarEgreso() {
    const [descripcion, setDescripcion] = useState('');
    const [monto, setMonto] = useState('');
    const [cuenta, setCuenta] = useState('');
    const [fecha, setFecha] = useState('');
    const [mensaje, setMensaje] = useState(null);
    const [empresaId, setEmpresaId] = useState(null);

    // Obtener la fecha actual y el IdEmpresa al cargar el componente
    useEffect(() => {
        const fechaActual = new Date().toISOString().split('T')[0];
        setFecha(fechaActual);

        // Obtener el objeto user desde localStorage
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const user = JSON.parse(storedUser);
            setEmpresaId(user.EmpresaId); // Asignar EmpresaId al estado
        }
    }, []);

    const handleRegistrarEgreso = async (e) => {
        e.preventDefault();

        const egresoData = {
            empresa_id: empresaId, // Incluir EmpresaId
            descripcion,
            monto,
            cuenta,
            fecha,
        };

        try {
            const response = await fetch('https://cam.gst-panel.com/finance/registrar_egreso.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(egresoData),
            });

            const data = await response.json();

            if (response.ok) {
                setMensaje({ type: 'success', text: 'Egreso registrado exitosamente.' });
                setDescripcion('');
                setMonto('');
                setCuenta('');
                setFecha(new Date().toISOString().split('T')[0]);
            } else {
                setMensaje({ type: 'error', text: data.message || 'Error al registrar el egreso.' });
            }
        } catch (error) {
            setMensaje({ type: 'error', text: 'Error de conexión con el servidor.' });
        }
    };

    return (
        <div className="container mt-5">
            <h2>Registrar Egreso</h2>
            {mensaje && <Alert variant={mensaje.type === 'error' ? 'danger' : 'success'}>{mensaje.text}</Alert>}
            <Form onSubmit={handleRegistrarEgreso}>
                <Form.Group controlId="formDescripcion">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Descripción del egreso"
                        value={descripcion}
                        onChange={(e) => setDescripcion(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formMonto">
                    <Form.Label>Monto</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Monto"
                        value={monto}
                        onChange={(e) => setMonto(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formCuenta">
                    <Form.Label>Cuenta Asignada</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Cuenta Asignada"
                        value={cuenta}
                        onChange={(e) => setCuenta(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formFecha">
                    <Form.Label>Fecha</Form.Label>
                    <Form.Control
                        type="date"
                        value={fecha}
                        onChange={(e) => setFecha(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-3">
                    Registrar Egreso
                </Button>
            </Form>
        </div>
    );
}

export default RegistrarEgreso;
