// src/services/authService.js

import axios from 'axios';

const BASE_URL = 'https://cam.gst-panel.com'; // Cambia esto a tu URL de backend

const login = async (username, password) => {

    const response = await axios.post(`${BASE_URL}/login.php`, {
        username,
        password
    });

    // Verificar la estructura de la respuesta
    console.log('Datos de respuesta:', response.data);

    if (response.status === 200 && response.data && response.data.user) {
        return response;
    } else {
        throw new Error(response.data.message || 'Respuesta inválida del servidor');
    }

};

export default {
    login
};
