// src/components/GestionCuentasCobro.js

import React, { useState, useEffect } from 'react';
import { Table, Button, Alert } from 'react-bootstrap';

function GestionCuentasCobro() {
    const [cuentasCobro, setCuentasCobro] = useState([]);
    const [mensaje, setMensaje] = useState(null);

    useEffect(() => {
        fetchCuentasCobro();
    }, []);

    const fetchCuentasCobro = async () => {
        try {
            const response = await fetch('http://tu-servidor/api/cuentas-cobro');
            const data = await response.json();

            if (response.ok) {
                setCuentasCobro(data);
            } else {
                setMensaje({ type: 'error', text: 'Error al obtener las cuentas de cobro.' });
            }
        } catch (error) {
            setMensaje({ type: 'error', text: 'Error de conexión con el servidor.' });
        }
    };

    return (
        <div className="container mt-5">
            <h2>Gestión de Cuentas de Cobro</h2>
            {mensaje && <Alert variant={mensaje.type === 'error' ? 'danger' : 'success'}>{mensaje.text}</Alert>}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Cliente</th>
                        <th>Monto</th>
                        <th>Fecha Vencimiento</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {cuentasCobro.map((cuenta) => (
                        <tr key={cuenta.ID}>
                            <td>{cuenta.ID}</td>
                            <td>{cuenta.ClienteID}</td>
                            <td>{cuenta.Monto}</td>
                            <td>{cuenta.FechaVencimiento}</td>
                            <td>{cuenta.Estado}</td>
                            <td>
                                <Button variant="warning" className="mr-2">
                                    Editar
                                </Button>
                                <Button variant="danger">Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default GestionCuentasCobro;
