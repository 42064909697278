import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import DashboardAdmin from './components/DashboardAdmin';
import FinanceDashboard from './components/FinanceDashboard';
import AssignBase from './components/AssignBase';
import RegistrarIngreso from './components/RegistrarIngreso';
import RegistrarEgreso from './components/RegistrarEgreso';
import AsignarIngreso from './components/AsignarIngreso';
import AsignarEgreso from './components/AsignarEgreso';
import GestionCuentasCobro from './components/GestionCuentasCobro';
import GestionDeudores from './components/GestionDeudores';
import AgregarUsuarios from './components/AgregarUsuarios';
import CreateQuotation from './components/CreateQuotation';
import AddProducts from './components/AddProducts';
import CrearCotizacion from './components/CrearCotizacion';
import Asignarvisitas from './components/AsignarVisitas';
import Vervisitas from './components/Vervisitas';
import Regitrodevisita from '../src/components/Tecnico/Rvisitas';
import DashboardTecnico from '../src/components/Tecnico/DashboardTecnico';

function App() {
  const [user, setUser] = useState(() => {
    const userData = localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
  });

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  const handleLogin = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/login"
            element={<Login onLogin={handleLogin} />}
          />
          <Route
            path="/register"
            element={<Register />}
          />

          <Route
            path="/agregar-usuario"
            element={user && user.Rol === "1" ? <AgregarUsuarios user={user} /> : <Navigate to="/login" />}
          />
          <Route
            path="/Vervisitas"
            element={user && user.Rol === "1" ? <Vervisitas user={user} /> : <Navigate to="/login" />}
          />
          <Route
            path="/CreateQuotation"
            element={user && user.Rol === "1" ? <CreateQuotation user={user} /> : <Navigate to="/login" />}
          />
          <Route
            path="/CrearCotizacion"
            element={user && user.Rol === "1" ? <CrearCotizacion user={user} /> : <Navigate to="/login" />}
          />
          <Route
            path="/Asignarvisitas"
            element={user && user.Rol === "1" ? <Asignarvisitas user={user} /> : <Navigate to="/login" />}
          />
          <Route
            path="/AddProducts"
            element={user && user.Rol === "1" ? <AddProducts user={user} /> : <Navigate to="/login" />}
          />
          <Route
            path="/dashboard/admin"
            element={user && user.Rol === "1" ? <DashboardAdmin user={user} /> : <Navigate to="/login" />}
          />
          <Route
            path="/dashboard/tecnico"
            element={user && user.Rol === "2" ? <DashboardTecnico user={user} /> : <Navigate to="/login" />}
          />
          <Route
            path="/registroVisitas/:id"
            element={user && user.Rol === "2" ? <Regitrodevisita user={user} /> : <Navigate to="/login" />}
          />
          <Route
            path="/finance"
            element={user && user.Rol === "1" ? <FinanceDashboard /> : <Navigate to="/login" />}
          />
          <Route
            path="/finance/assign-base"
            element={user && user.Rol === "1" ? <AssignBase /> : <Navigate to="/login" />}
          />
          <Route
            path="/finance/register-income"
            element={user && user.Rol === "1" ? <RegistrarIngreso /> : <Navigate to="/login" />}
          />
          <Route
            path="/finance/register-expense"
            element={user && user.Rol === "1" ? <RegistrarEgreso /> : <Navigate to="/login" />}
          />
          <Route
            path="/finance/assign-income"
            element={user && user.Rol === "1" ? <AsignarIngreso /> : <Navigate to="/login" />}
          />
          <Route
            path="/finance/assign-expense"
            element={user && user.Rol === "1" ? <AsignarEgreso /> : <Navigate to="/login" />}
          />
          <Route
            path="/finance/manage-accounts"
            element={user && user.Rol === "1" ? <GestionCuentasCobro /> : <Navigate to="/login" />}
          />
          <Route
            path="/finance/manage-debtors"
            element={user && user.Rol === "1" ? <GestionDeudores /> : <Navigate to="/login" />}
          />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
