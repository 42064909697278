import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AsignarVisitas = ({ user }) => {
    const { EmpresaId } = user;
    const [empresa, setEmpresa] = useState(null);
    const [clientes, setClientes] = useState([]);
    const [selectedEmpleado, setSelectedEmpleado] = useState(null);

    const [filteredClientes, setFilteredClientes] = useState([]);
    const [empleados, setEmpleados] = useState([]);
    const [selectedCliente, setSelectedCliente] = useState(null);
    const [fechaVisita, setFechaVisita] = useState('');
    const [descripcionVisita, setDescripcionVisita] = useState('');
    const [tipoVisita, setTipoVisita] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); // Nuevo estado para el mensaje de éxito

    const [estadoVisita, setEstadoVisita] = useState('Pendiente');
    const [empleadoId, setEmpleadoId] = useState(null);


    const [showNuevoClienteForm, setShowNuevoClienteForm] = useState(true);
    const [nuevoCliente, setNuevoCliente] = useState({
        nombre: '',
        apellido: '',
        correo: '',
        telefono: '',
        direccion: '',
        empresa: EmpresaId,
    });

    useEffect(() => {
        const fetchEmpresa = async () => {
            try {
                const response = await axios.get(`https://cam.gst-panel.com/getEmpresa.php?id=${EmpresaId}`);
                setEmpresa(response.data);
            } catch (err) {
                console.error('Error al obtener los datos de la empresa:', err);
            }
        };
        fetchEmpresa();
    }, [EmpresaId]);

    useEffect(() => {
        const fetchEmpleados = async () => {
            try {
                const response = await axios.get(`https://cam.gst-panel.com/getUsuaios.php?id=${EmpresaId}`);
                const tecnicos = response.data.filter(usuario => usuario.Rol === "2");
                setEmpleados(tecnicos);
            } catch (error) {
                console.error("Error al obtener los empleados:", error);
            }
        };

        fetchEmpleados();
    }, [EmpresaId]);

    // Buscar cliente mientras se escribe
    const handleClienteSearch = async (query) => {
        if (!query) {
            setFilteredClientes([]);
            return;
        }

        try {
            const response = await axios.get(`https://cam.gst-panel.com/cotizacion/clientes.php?id_empresa=${EmpresaId}`);
            const clientesFiltrados = response.data.filter(cliente =>
                cliente.Nombre.toLowerCase().includes(query.toLowerCase()) ||
                cliente.Apellido.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredClientes(clientesFiltrados);
        } catch (error) {
            console.error("Error al buscar el cliente:", error);
        }
    };

    // Seleccionar cliente de la lista de autocompletado
    const handleSelectCliente = (cliente) => {
        setSelectedCliente(cliente.ID);
        setNuevoCliente({
            nombre: cliente.Nombre,
            apellido: cliente.Apellido,
            correo: cliente.Correo,
            telefono: cliente.Telefono,
            direccion: cliente.Direccion,
            empresa: EmpresaId,
        });
        console.log("esto es lo que voy a imprimir empresa ID ", EmpresaId);
        setFilteredClientes([]);
        setShowNuevoClienteForm(false); // Oculta el formulario de nuevo cliente
    };

    // Crear nuevo cliente si no existe
    const handleNuevoClienteSubmit = async (e) => {
        e.preventDefault(); // Evitar el envío predeterminado si hay un formulario externo

        // Verifica que todos los campos requeridos estén completos antes de enviar
        console.log("Datos del cliente a enviar:", nuevoCliente);

        try {
            // Asegúrate de enviar al endpoint correcto
            const response = await axios.post('https://cam.gst-panel.com/cotizacion/crearcliente.php', nuevoCliente);
            console.log("Respuesta del servidor:", response.data); // Muestra la respuesta del servidor

            if (response.data.success) {
                console.log("Cliente creado exitosamente");
                setShowNuevoClienteForm(false); // Oculta el formulario de creación
                setClientes([...clientes, response.data.cliente]); // Añade el nuevo cliente a la lista de clientes
            } else {
                console.error("Error en la creación del cliente:", response.data.message);
                alert("Hubo un problema al crear el cliente: " + response.data.message);
            }
        } catch (error) {
            console.error("Error al crear el cliente:", error.message);
            alert("Hubo un problema al crear el cliente: " + error.message);
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedCliente || !fechaVisita || !tipoVisita) {
            alert("Por favor, complete todos los campos obligatorios.");
            return;
        }

        try {
            // Imprime los valores individuales que se están enviando
            console.log("Cliente ID:", selectedCliente);
            console.log("Fecha y Hora de Visita:", fechaVisita);
            console.log("Tipo de Visita:", tipoVisita);
            console.log("Descripción de Visita:", descripcionVisita);
            console.log("Estado de Visita:", estadoVisita);
            console.log("Empleado ID:", empleadoId);

            // Crea el objeto con los datos que se van a enviar y lo imprime en consola
            const visitaData = {
                clienteId: selectedCliente,
                fechaHoraVisita: fechaVisita,
                tipoVisita,
                descripcionVisita,
                estadoVisita,
                empleadoId, // Usamos empleadoId aquí
            };
            console.log("Datos de la visita que se enviarán:", visitaData);

            // Envía la solicitud con los datos
            const response = await axios.post('https://cam.gst-panel.com/asignarvisita.php', visitaData);

            console.log("Respuesta del servidor:", response.data); // Muestra la respuesta del servidor
            setSuccessMessage(response.data.message); // Asigna el mensaje de éxito
            setTimeout(() => {
                setSuccessMessage(''); // Oculta el mensaje después de unos segundos
            }, 3000);

            // Resetear formulario
            setSelectedCliente(null);
            setFechaVisita('');
            setTipoVisita('');
            setDescripcionVisita('');
            setEstadoVisita('Pendiente');
            setNuevoCliente({
                nombre: '',
                apellido: '',
                // ... otros campos
            });
            setFilteredClientes([]);
            setShowNuevoClienteForm(true);

        } catch (error) {
            console.error("Error al asignar la visita:", error.message);
            alert("Hubo un problema al asignar la visita: " + error.message);
        }

    };



    return (

        <div>
            <center><h1>{empresa ? empresa.NombreEmpresa : "Cargando..."}</h1></center>
            <h2>Bienvenidos a la asignación de visitas</h2>

            <form onSubmit={handleSubmit}>
                <div>
                    <p style={{ color: 'green' }}>{successMessage}</p> </div>
                {/* ... */}
                <div>
                    <label htmlFor="cliente">Buscar Cliente:</label>
                    <input
                        type="text"
                        placeholder="Escriba el nombre o apellido"
                        onChange={(e) => {
                            setNuevoCliente({ ...nuevoCliente, nombre: e.target.value });
                            handleClienteSearch(e.target.value);
                        }}
                        value={nuevoCliente.nombre}
                        style={{
                            backgroundColor: selectedCliente ? 'lightgreen' : 'white',
                            borderColor: selectedCliente ? 'green' : 'gray'
                        }}
                    />
                    {filteredClientes.length > 0 && (
                        <div style={{ border: '1px solid #ccc', maxHeight: '150px', overflowY: 'auto', position: 'absolute', backgroundColor: 'white' }}>
                            {filteredClientes.map((cliente) => (
                                <div key={cliente.ID} onClick={() => handleSelectCliente(cliente)} style={{ padding: '8px', cursor: 'pointer' }}>
                                    {cliente.Nombre} {cliente.Apellido} - {cliente.Correo}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* Formulario de nuevo cliente */}
                {showNuevoClienteForm && !selectedCliente && (

                    <div>
                        <h3>Agregar Nuevo Cliente</h3>
                        {/* Envolvemos el formulario en un fragmento en lugar de un <form> */}
                        <>
                            <input
                                type="text"
                                placeholder="Nombre"
                                value={nuevoCliente.nombre}
                                onChange={(e) => setNuevoCliente({ ...nuevoCliente, nombre: e.target.value })}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Apellido"
                                value={nuevoCliente.apellido}
                                onChange={(e) => setNuevoCliente({ ...nuevoCliente, apellido: e.target.value })}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Correo"
                                value={nuevoCliente.correo}
                                onChange={(e) => setNuevoCliente({ ...nuevoCliente, correo: e.target.value })}
                                required
                            />
                            <input
                                type="tel"
                                placeholder="Teléfono"
                                value={nuevoCliente.telefono}
                                onChange={(e) => setNuevoCliente({ ...nuevoCliente, telefono: e.target.value })}
                            />
                            <input
                                type="text"
                                placeholder="Dirección"
                                value={nuevoCliente.direccion}
                                onChange={(e) => setNuevoCliente({ ...nuevoCliente, direccion: e.target.value })}
                            />
                            {/* Cambiamos el tipo de botón a "button" */}
                            <button type="button" onClick={handleNuevoClienteSubmit}>Crear Cliente</button>
                        </>
                    </div>
                )}



                <div>
                    <label htmlFor="empleado">Seleccionar Técnico:</label>
                    <select
                        id="empleado"
                        value={selectedEmpleado}

                        onChange={(e) => {
                            setSelectedEmpleado(e.target.value);
                            setEmpleadoId(e.target.value); // Actualizamos empleadoId
                        }}

                        required
                    >
                        <option value="">Seleccione un técnico</option>
                        {empleados.map(empleado => (
                            <option key={empleado.ID} value={empleado.ID}>
                                {empleado.Nombre} {empleado.Apellido}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label htmlFor="fecha">Fecha de Visita:</label>
                    <input
                        type="datetime-local"
                        id="fecha"
                        value={fechaVisita}
                        onChange={(e) => setFechaVisita(e.target.value)}
                        required
                    />
                </div>

                <div>
                    <label htmlFor="tipo">Tipo de Visita:</label>
                    <input
                        type="text"
                        id="tipo"
                        value={tipoVisita}
                        onChange={(e) => setTipoVisita(e.target.value)}
                        required
                    />
                </div>

                <div>
                    <label htmlFor="descripcion">Descripción:</label>
                    <textarea
                        id="descripcion"
                        value={descripcionVisita}
                        onChange={(e) => setDescripcionVisita(e.target.value)}
                    ></textarea>
                </div>

                <div>
                    <label htmlFor="estado">Estado de Visita:</label>
                    <select
                        id="estado"
                        value={estadoVisita}
                        onChange={(e) => setEstadoVisita(e.target.value)}
                    >
                        <option value="Pendiente">Pendiente</option>
                        <option value="Completada">Completada</option>
                    </select>
                </div>

                <button type="submit">Asignar Visita</button>
            </form>
        </div>
    );
};

export default AsignarVisitas;
