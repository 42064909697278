// src/components/FinanceDashboard.js

import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const FinanceDashboard = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col xs={12} md={8}>
                    <h1 className="text-center">Gestión Financiera</h1>
                    <p className="text-center text-muted">Panel de Control Financiero</p>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Asignar Base a Técnicos</Card.Title>
                            <Card.Text>Distribuye recursos financieros a técnicos específicos.</Card.Text>
                            <Button
                                variant="primary"
                                className="w-100"
                                onClick={() => handleNavigation('/finance/assign-base')}
                            >
                                Asignar Base
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Registrar Ingreso</Card.Title>
                            <Card.Text>Registra nuevas fuentes de ingresos.</Card.Text>
                            <Button
                                variant="success"
                                className="w-100"
                                onClick={() => handleNavigation('/finance/register-income')}
                            >
                                Ingresos
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Registrar Egreso</Card.Title>
                            <Card.Text>Registra los egresos realizados por la empresa.</Card.Text>
                            <Button
                                variant="danger"
                                className="w-100"
                                onClick={() => handleNavigation('/finance/register-expense')}
                            >
                                Egresos
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Asignar Ingreso</Card.Title>
                            <Card.Text>Asigna ingresos a cuentas específicas.</Card.Text>
                            <Button
                                variant="info"
                                className="w-100"
                                onClick={() => handleNavigation('/finance/assign-income')}
                            >
                                Asignar Ingreso
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Asignar Egreso</Card.Title>
                            <Card.Text>Asigna egresos a cuentas o proyectos específicos.</Card.Text>
                            <Button
                                variant="warning"
                                className="w-100"
                                onClick={() => handleNavigation('/finance/assign-expense')}
                            >
                                Asignar Egreso
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Gestión de Cuentas de Cobro</Card.Title>
                            <Card.Text>Administra las cuentas de cobro de la empresa.</Card.Text>
                            <Button
                                variant="secondary"
                                className="w-100"
                                onClick={() => handleNavigation('/finance/manage-accounts')}
                            >
                                Cuentas de Cobro
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <Card.Title>Gestión de Deudores</Card.Title>
                            <Card.Text>Revisa y gestiona los deudores actuales.</Card.Text>
                            <Button
                                variant="dark"
                                className="w-100"
                                onClick={() => handleNavigation('/finance/manage-debtors')}
                            >
                                Deudores
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default FinanceDashboard;
