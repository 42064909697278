import React, { useState, useEffect } from 'react';
import './TecnicoDashboard.css';

function DashboardTecnico({ user }) {
    const { ID: EmpleadoID, Nombre, Apellido, EmpresaId } = user;
    const [visitasHoy, setVisitasHoy] = useState([]);
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [visitasRango, setVisitasRango] = useState([]);
    const [gastoDescripcion, setGastoDescripcion] = useState('');
    const [gastoMonto, setGastoMonto] = useState('');

    useEffect(() => {
        // Verifica si EmpleadoID existe
        if (!EmpleadoID) {
            console.error("EmpleadoID no está definido");
            return;
        }
        // Fetch today's visits
        fetch(`https://cam.gst-panel.com/tecnico/getVisitasHoy.php?EmpleadoID=${EmpleadoID}`)

            .then((res) => res.json())
            .then((data) => {
                console.log("Visitas del día:", data); // Para depuración
                if (Array.isArray(data)) {
                    setVisitasHoy(data);
                } else {
                    setVisitasHoy([]); // En caso de no recibir un array válido
                }
            })
            .catch((err) => {
                console.error("Error al obtener visitas del día:", err);
                setVisitasHoy([]); // En caso de error, vaciar visitas
            });
    }, [EmpleadoID]);

    const handleRegistrarGasto = (e) => {
        e.preventDefault();
        const payload = {
            EmpresaId,
            Descripcion: gastoDescripcion,
            Monto: gastoMonto,
        };

        fetch('https://cam.gst-panel.com/tecnico/addGasto.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .then((data) => alert(data.message))
            .catch((err) => console.error("Error al registrar gasto:", err));
    };

    const handleConsultarVisitas = () => {
        fetch(`https://cam.gst-panel.com/tecnico/getVisitasRango.php?EmpleadoID=${EmpleadoID}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`)
            .then((res) => res.json())
            .then((data) => setVisitasRango(data))
            .catch((err) => console.error("Error al consultar visitas por rango:", err));
    };

    return (
        <div className="registro-visita-form">
            <h2 className="dashboard-title">Panel de Control del Técnico {EmpleadoID}</h2>

            {/* Registrar Gastos */}
            <div className="card neon-card">
                <h3>Registrar Gastos</h3>
                <form onSubmit={handleRegistrarGasto}>
                    <input
                        type="text"
                        placeholder="Descripción del gasto"
                        value={gastoDescripcion}
                        onChange={(e) => setGastoDescripcion(e.target.value)}
                        required
                    />
                    <input
                        type="number"
                        placeholder="Monto"
                        value={gastoMonto}
                        onChange={(e) => setGastoMonto(e.target.value)}
                        required
                    />
                    <button type="submit" className="neon-button">Agregar Gasto</button>
                </form>
            </div>

            {/* Visitas del Día */}
            <div className="card neon-card">
                <h3>Visitas del Día</h3>
                <ul>
                    {Array.isArray(visitasHoy) && visitasHoy.length > 0 ? (
                        visitasHoy.map((visita) => (
                            <li
                                key={visita.ID}
                                className={`visita-item ${visita.EstadoVisita === 'Pendiente' ? 'red' : 'green'}`}
                                onClick={() => window.location.href = `/registroVisitas/${visita.ID}`}
                            >
                                {visita.DescripcionVisita}
                            </li>
                        ))
                    ) : (
                        <p>No hay visitas registradas para hoy.</p>
                    )}
                </ul>
            </div>

            {/* Consultar Visitas por Fecha */}
            <div className="card neon-card">
                <h3>Consultar Visitas por Fecha</h3>
                <input
                    type="date"
                    value={fechaInicio}
                    onChange={(e) => setFechaInicio(e.target.value)}
                />
                <input
                    type="date"
                    value={fechaFin}
                    onChange={(e) => setFechaFin(e.target.value)}
                />
                <button onClick={handleConsultarVisitas} className="neon-button">Consultar</button>
                <ul>
                    {visitasRango.map((visita) => (
                        <li key={visita.ID}>{visita.DescripcionVisita}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default DashboardTecnico;
