import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const AssignBase = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState('');
    const [valor, setValor] = useState('');
    const [dia, setDia] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [idEmpresas, setIdEmpresas] = useState('');

    useEffect(() => {
        const storedUser = localStorage.getItem('user');

        if (storedUser) {
            const user = JSON.parse(storedUser);
            if (user.EmpresaId) {
                setIdEmpresas(user.EmpresaId);
            }
        }

        const fetchUsuarios = async () => {
            try {
                const response = await fetch('https://cam.gst-panel.com/finance/obtener_usuarios_empresa.php', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ IdEmpresas: parseInt(idEmpresas) }),
                });

                const data = await response.json();

                if (response.ok && Array.isArray(data)) {
                    setUsuarios(data);
                } else {
                    console.error('Error en la respuesta del servidor:', data.message);
                }
            } catch (error) {
                console.error('Error al obtener los usuarios:', error);
            }
        };

        fetchUsuarios();
    }, [idEmpresas]);

    const handleRegistrarBase = async (e) => {
        e.preventDefault();

        const baseData = {
            IdUsuario: parseInt(usuarioSeleccionado),
            Valor: parseFloat(valor),
            Dia: dia,
            Descripcion: descripcion,
            ingresouOegresoc: 1,
        };

        try {
            const response = await fetch('https://cam.gst-panel.com/finance/registrar_base.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(baseData),
            });

            const data = await response.json();

            if (response.ok) {
                alert('Base registrada exitosamente');
                // Vaciar los campos del formulario después del registro exitoso
                setUsuarioSeleccionado('');
                setValor('');
                setDia('');
                setDescripcion('');
            } else {
                console.error('Error al registrar la base:', data.message);
                alert(`Error: ${data.message}`);
            }
        } catch (error) {
            console.error('Error de conexión:', error);
            alert('Error de conexión');
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col xs={12} md={8}>
                    <h1 className="text-center">Registrar Base {idEmpresas}</h1>
                    <Form onSubmit={handleRegistrarBase}>
                        <Form.Group controlId="formUsuario">
                            <Form.Label>Seleccionar Usuario</Form.Label>
                            <Form.Control
                                as="select"
                                value={usuarioSeleccionado}
                                onChange={(e) => setUsuarioSeleccionado(e.target.value)}
                                required
                            >
                                <option value="">Seleccionar...</option>
                                {usuarios.map((usuario) => (
                                    <option key={usuario.ID} value={usuario.ID}>
                                        {usuario.Nombre}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formValor">
                            <Form.Label>Valor</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Valor"
                                value={valor}
                                onChange={(e) => setValor(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formDia">
                            <Form.Label>Día</Form.Label>
                            <Form.Control
                                type="date"
                                value={dia}
                                onChange={(e) => setDia(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formDescripcion">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Descripción"
                                value={descripcion}
                                onChange={(e) => setDescripcion(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="mt-3">
                            Registrar Base
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default AssignBase;
