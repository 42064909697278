import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';
import '../../src/VerVisitas.css';

const Vervisitas = ({ user }) => {
    const { EmpresaId } = user;
    const [visitas, setVisitas] = useState([]);
    const [filteredVisitas, setFilteredVisitas] = useState([]);
    const [estadoFiltro, setEstadoFiltro] = useState('todo');
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [searchTecnico, setSearchTecnico] = useState('');
    const [searchDescripcion, setSearchDescripcion] = useState('');

    // Obtener color según el estado de la visita
    const getStatusColor = (fechaVisita) => {
        const today = new Date();
        const visitaDate = new Date(fechaVisita);

        if (visitaDate < today) {
            return '#e57373'; // Rojo suave para visitas pasadas
        }
        if (visitaDate.toDateString() === today.toDateString()) {
            return '#81c784'; // Verde claro para visitas del día actual
        }
        return '#64b5f6'; // Azul para visitas futuras
    };

    // Filtrar visitas
    const filterVisitas = () => {
        let filtered = visitas;

        // Filtro por estado
        if (estadoFiltro === 'pendiente') {
            filtered = filtered.filter(visita => visita.EstadoVisita === 'Pendiente');
        } else if (estadoFiltro === 'realizada') {
            filtered = filtered.filter(visita => visita.EstadoVisita === 'Realizada');
        }

        // Filtro por rango de fechas
        if (fechaInicio && fechaFin) {
            filtered = filtered.filter(visita => {
                const visitaDate = new Date(visita.FechaHoraVisita);
                return visitaDate >= new Date(fechaInicio) && visitaDate <= new Date(fechaFin);
            });
        }

        // Filtro por técnico y descripción
        if (searchTecnico) {
            filtered = filtered.filter(visita =>
                visita.NombreTecnico.toLowerCase().includes(searchTecnico.toLowerCase())
            );
        }
        if (searchDescripcion) {
            filtered = filtered.filter(visita =>
                visita.DescripcionVisita.toLowerCase().includes(searchDescripcion.toLowerCase())
            );
        }

        setFilteredVisitas(filtered);
    };

    // Obtener visitas desde el API
    useEffect(() => {
        const fetchVisitas = async () => {
            try {
                const response = await axios.post('https://cam.gst-panel.com/ver_visita.php', {
                    EmpresaId: EmpresaId
                });

                const visitasOrdenadas = response.data.visitas.sort((a, b) => {
                    return new Date(a.FechaHoraVisita) - new Date(b.FechaHoraVisita);
                });

                setVisitas(visitasOrdenadas);
                setFilteredVisitas(visitasOrdenadas);
            } catch (error) {
                console.error('Error fetching visitas:', error);
            }
        };

        fetchVisitas();
    }, [EmpresaId]);

    // Actualizar el filtro cada vez que cambian las variables
    useEffect(() => {
        filterVisitas();
    }, [estadoFiltro, fechaInicio, fechaFin, searchTecnico, searchDescripcion, visitas]);

    return (
        <div className="visitas-container">
            <div className="status-buttons">
                <button onClick={() => setEstadoFiltro('pendiente')}>Visitas Pendientes</button>
                <button onClick={() => setEstadoFiltro('realizada')}>Visitas Realizadas</button>
                <button onClick={() => setEstadoFiltro('todo')}>Mostrar Todo</button>
            </div>

            <div className="filters">
                <input
                    type="date"
                    value={fechaInicio}
                    onChange={(e) => setFechaInicio(e.target.value)}
                    placeholder="Fecha Inicio"
                />
                <input
                    type="date"
                    value={fechaFin}
                    onChange={(e) => setFechaFin(e.target.value)}
                    placeholder="Fecha Fin"
                />
                <input
                    type="text"
                    placeholder="Buscar por técnico"
                    value={searchTecnico}
                    onChange={(e) => setSearchTecnico(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Buscar por descripción"
                    value={searchDescripcion}
                    onChange={(e) => setSearchDescripcion(e.target.value)}
                />
            </div>

            <div className="visitas-grid">
                {filteredVisitas.map(visita => (
                    <div
                        key={visita.ID}
                        className={`visita-card ${new Date(visita.FechaHoraVisita).toLocaleDateString() === new Date().toLocaleDateString() ? 'highlight-today' : ''}`}
                        style={{
                            borderLeft: `6px solid ${getStatusColor(visita.FechaHoraVisita)}`,
                            backgroundColor: new Date(visita.FechaHoraVisita).toLocaleDateString() === new Date().toLocaleDateString() ? '#ffffff' : '#f1f1f1'
                        }}
                    >
                        <h3>{visita.TipoVisita}</h3>
                        <p><strong>Descripción:</strong> {visita.DescripcionVisita}</p>
                        <p><strong>Fecha y Hora:</strong> {new Date(visita.FechaHoraVisita).toLocaleString()}</p>
                        <p><strong>Estado:</strong> {visita.EstadoVisita}</p>
                        <p><strong>Técnico Asignado:</strong> {visita.NombreTecnico || 'No asignado'}</p>

                        <div className="contact-icons">
                            {visita.CelularTecnico && (
                                <>
                                    <a href={`https://wa.me/${visita.CelularTecnico}`} target="_blank" rel="noopener noreferrer">
                                        <FaWhatsapp className="contact-icon whatsapp" />
                                    </a>
                                    <a href={`tel:${visita.CelularTecnico}`} target="_blank" rel="noopener noreferrer">
                                        <FaPhone className="contact-icon phone" />
                                    </a>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Vervisitas;

