import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';

const CreateQuotation = () => {
    const navigate = useNavigate();

    const handleOptionClick = (path) => {
        navigate(path);
    };

    return (
        <Container className="mt-5 p-4 border rounded shadow-lg bg-white">
            <h2 className="text-center mb-4">Crear Cotización</h2>
            <Row className="justify-content-center">
                <Col md={8}>
                    <div className="d-grid gap-3">
                        <Button
                            variant="primary"
                            size="lg"
                            className="py-3"
                            onClick={() => handleOptionClick('/CrearCotizacion')}
                        >
                            Crear Cotización con Cliente Existente
                        </Button>
                        <Button
                            variant="success"
                            size="lg"
                            className="py-3"
                            onClick={() => handleOptionClick('/create-quotation-new-client')}
                        >
                            Crear Cotización sin Cliente Existente
                        </Button>
                        <Button
                            variant="warning"
                            size="lg"
                            className="py-3"
                            onClick={() => handleOptionClick('/AddProducts')}
                        >
                            Agregar Productos
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default CreateQuotation;
