import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

const AddProducts = () => {
    const [descripcion, setDescripcion] = useState('');
    const [valorCompra, setValorCompra] = useState('');
    const [valorVenta, setValorVenta] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [linkImagen, setLinkImagen] = useState('');
    const [mensaje, setMensaje] = useState(null);
    const [empresaId, setEmpresaId] = useState(null);

    // Obtener id_empresa desde el localStorage o desde otra fuente
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const user = JSON.parse(storedUser);
            if (user.EmpresaId) {
                setEmpresaId(user.EmpresaId);
            }
        }
    }, []);

    const handleAddProduct = async (e) => {
        e.preventDefault();

        const productoData = {
            descripcion,
            valor_compra: parseFloat(valorCompra),
            valor_venta: parseFloat(valorVenta),
            cantidad: parseInt(cantidad),
            link_imagen: linkImagen,
            id_empresa: empresaId, // Relaciona el producto con la empresa
        };

        try {
            const response = await fetch('https://cam.gst-panel.com/cotizacion/agregar_productos.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(productoData),
            });

            const data = await response.json();
            if (data.success) {
                setMensaje({ type: 'success', text: data.message });
                // Limpiar los campos del formulario
                setDescripcion('');
                setValorCompra('');
                setValorVenta('');
                setCantidad('');
                setLinkImagen('');
            } else {
                setMensaje({ type: 'error', text: data.message });
            }
        } catch (error) {
            setMensaje({ type: 'error', text: 'Error al conectar con el servidor' });
        }
    };

    return (
        <div className="container mt-5">
            <h2>Agregar Producto</h2>
            {mensaje && <Alert variant={mensaje.type === 'error' ? 'danger' : 'success'}>{mensaje.text}</Alert>}
            <Form onSubmit={handleAddProduct}>
                <Form.Group controlId="formDescripcion">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Descripción del producto"
                        value={descripcion}
                        onChange={(e) => setDescripcion(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formValorCompra">
                    <Form.Label>Valor de Compra</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Valor de compra"
                        value={valorCompra}
                        onChange={(e) => setValorCompra(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formValorVenta">
                    <Form.Label>Valor de Venta</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Valor de venta"
                        value={valorVenta}
                        onChange={(e) => setValorVenta(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formCantidad">
                    <Form.Label>Cantidad</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Cantidad"
                        value={cantidad}
                        onChange={(e) => setCantidad(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formLinkImagen">
                    <Form.Label>Link de Imagen</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="URL de la imagen"
                        value={linkImagen}
                        onChange={(e) => setLinkImagen(e.target.value)}
                    />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-3">
                    Agregar Producto
                </Button>
            </Form>
        </div>
    );
};

export default AddProducts;
